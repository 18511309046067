import React, { Component } from 'react'; //different


class App extends Component {


  onClick = (e) => {
    e.preventDefault()
    ////console.log('onclick..')
  }
  render() {
    return (
      <>

        <footer className="x-footer -anon">
          <div className="x-slot-providers-logo-component  -gray-theme">
            <div className="container -providers-logo-inner">
              <img src="/build/web/ez-bet/img/ez-slot-logo-evo-play.png" className="-img " alt="GPAY88BET evo-play logo png" data-animatable="fadeInUp" data-delay={100} />
              <img src="/build/web/ez-bet/img/ez-slot-logo-sp.png" className="-img " alt="GPAY88BET sp logo png" data-animatable="fadeInUp" data-delay={200} />
              <img src="/build/web/ez-bet/img/ez-slot-logo-ps.png" className="-img " alt="GPAY88BET ps logo png" data-animatable="fadeInUp" data-delay={300} />
              <img src="/build/web/ez-bet/img/ez-slot-logo-kingmaker.png" className="-img " alt="GPAY88BET kingmaker logo png" data-animatable="fadeInUp" data-delay={400} />
              <img src="/build/web/ez-bet/img/ez-slot-logo-joker.png" className="-img " alt="GPAY88BET joker logo png" data-animatable="fadeInUp" data-delay={500} />
              <img src="/build/web/ez-bet/img/ez-slot-logo-habanero.png" className="-img " alt="GPAY88BET habanero logo png" data-animatable="fadeInUp" data-delay={600} />
              <img src="/build/web/ez-bet/img/ez-slot-logo-ameba.png" className="-img " alt="GPAY88BET ameba logo png" data-animatable="fadeInUp" data-delay={700} />
              <img src="/build/web/ez-bet/img/ez-slot-logo-cq9.png" className="-img " alt="GPAY88BET cq9 logo png" data-animatable="fadeInUp" data-delay={800} />
              <img src="/build/web/ez-bet/img/ez-slot-logo-pg.png" className="-img -pg" alt="GPAY88BET pg logo png" data-animatable="fadeInUp" data-delay={900} />
              <img src="/build/web/ez-bet/img/ez-slot-logo-rich88.png" className="-img " alt="GPAY88BET rich88 logo png" data-animatable="fadeInUp" data-delay={1000} />
              <img src="/build/web/ez-bet/img/ez-slot-logo-goldy.png" className="-img " alt="GPAY88BET goldy logo png" data-animatable="fadeInUp" data-delay={1100} />
            </div>
          </div>
          <div className="-inner-wrapper lazyload">
            <div className="container -image-wrapper">
              <img src="/build/web/ez-bet/img/logo-black.png?v=1" alt="ez-bet" className="img-fluid -img" />
              <h2 className="-text"> สกิลเกม เจ้าแรกในไทย</h2>
              <h3 className="-sub-text"><span className="-highlight">GPAY88BET</span> Slot Online FullHD รองรับ App iOS, Android สล็อต
                คาสิโน ยิงปลา และกีฬา ครบจบ เว็บเดียวจากค่ายดังทุกมุมโลก GPAY88BET มีมากกว่า แต่ง่ายกว่า เกมเยอะ ภาพสวยใหม่
                และ เป็นที่นิยม เรารวม สล็อตออนไลน์ และ เกมสล็อต มากที่สุดในไทย ด้วยระบบเติมถอนอัจฉริยะ เล่นง่าย รวดเร็ว
                ฝากถอนออโต้ เจ้าเดียวที่ใช้ได้จริง</h3>
            </div>
            <div className="container">
              <div className="row x-footer-seo">
                <div className="col-12 -tags">
                  <a href="/" className="btn btn-sm">slot</a>
                  <a href="/" className="btn btn-sm">slot online</a>
                  <a href="/" className="btn btn-sm">GPAY88BET</a>
                  <a href="/" className="btn btn-sm">game slot</a>
                  <a href="/" className="btn btn-sm">gameslot</a>
                  <a href="/" className="btn btn-sm">สล็อต</a>
                  <a href="/" className="btn btn-sm">สล็อตออนไลน์</a>
                  <a href="/" className="btn btn-sm">เกมสล็อต</a>
                  <a href="/" className="btn btn-sm">ยิงปลา</a>
                  <a href="/" className="btn btn-sm">เกมยิงปลา</a>
                  <a href="/" className="btn btn-sm">joker</a>
                  <a href="/" className="btn btn-sm">evoplay</a>
                  <a href="/" className="btn btn-sm">simpleplay</a>
                  <a href="/" className="btn btn-sm">playstar</a>
                  <a href="/" className="btn btn-sm">kingmaker</a>
                  <a href="/" className="btn btn-sm">habanero</a>
                </div>
              </div>
            </div>
            <div className="-animater-footer">
              <img data-src="/build/web/ez-bet/img/ez-slot-animate-pilot-tiny-good.png" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" className="-mascot-img lazyload" alt="Mascot" />
              <img data-src="/build/web/ez-bet/img/ez-slot-ic-coin-ranking.png" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" className="-coin-img lazyload" alt="coin" />
            </div>
          </div>
          <div className="text-center -copy-right-container">
            <p className="mb-0 -copy-right-text">
              Copyright © 2023 All Rights Reserved.
            </p>
          </div>
        </footer>


      </>
    );
  }
}
export default App;
