import React, { Component } from 'react'; //different

import Footer from './footer';
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2'
import instance from "./axios-instance";
import { NumericFormat } from 'react-number-format';
import Systems from "./SystemFunction";
import moment from 'moment';
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            rewardlist: [],
            activePage: 1,
            pagecount: 1,
            products_page_count: 1,
            isActive: false,
            activeTab: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: true, // Indicates in progress state of login form
            linelink: null,
            linetelegramlink: null,
            userid: null,
            point: 0,
            balance: 0,
            loginstate: false,
            firstname: null,
            lastname: null,
            alert: null,
            modalOpen: false,
            handleClose: false,
            proderedeemlist: [],
            token: window.localStorage.getItem("token"),
            type: "",
            username: "",
            amount: "",
            remark: "",
            mainacc: "",
            rankpic: "1.jpeg",
            rankname: null,
            rankob: null,
            rankpoint: 0,
            name: "",
            password: "",
            page: "",
            left: false,
        }
    }
    logout = (e) => {

        // window.location.href = '/logout';

    }
    async componentDidMount() {
        const search = window.location.href;
        const params = new URLSearchParams(search);
        const space = search.split("/")
        var menu;

        if (space.length == 6) menu = space[4].replace("#", "")
        else menu = space[3].replace("#", "")
        this.setState({
            page: menu,
        });
        await instance.post("/api/v1/mainsetting", {
            System: Systems,
        }).then((res) => {
            if (res.data.status === 200) {
                const datas = res.data.message;
                console.log(123, datas);
                this.setState({
                    linelink: datas[1].value,
                    linetelegramlink: datas[6].value,
                });
            }
            else {
                // this.CheckThisGoalError();
            }
        });
        var userAgent = navigator.userAgent.toLowerCase()
        // if (userAgent.indexOf('safari') != -1) {
        //     if (userAgent.indexOf('chrome') > -1) {
        //     } else {
        //         await Swal.fire({
        //             title: 'Warning!',
        //             text: 'แนะนำให้ใช้งานบน Google Chorme เพื่อประสิทธิภาพที่ดีกว่า',
        //             icon: 'warning',
        //             confirmButtonText: 'ตกลง'
        //         })
        //     }
        // }

        try {
            var token = localStorage.getItem('auth_token');
            //  //////console.log(token);
            if (token != null) {
                var decoded = jwt_decode(token);
                var Username = decoded.message.playerid;
                await this.rechecklogin(Username, token);
                this.setState({
                    userid: decoded.message.playerid,
                    username: decoded.message.playerid,
                    firstname: decoded.message.firstname,
                    lastname: decoded.message.lastname,
                    loginstate: true,
                });
            }
            else {
                this.setState({
                    loginstate: false,
                });
            }
        }
        catch (error) {
            //////console.log();
        }

    }
    openAccout(evt, cityName) {
        //  console.log(21326356);
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("x-menu-account-list-sidebar");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].className += " -open";
        }
    }



    cancelredeempromotion = async (e, index) => {
        e.preventDefault();
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            var Username = decoded.message.playerid;
            Swal.fire({
                icon: 'warning',
                title: 'คุณต้องการยกเลิกการใช้โปรใช่ หรือ ไม่?',
                showCancelButton: true,
                confirmButtonText: 'Save',
            }).then(async (result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    await instance.post("/api/v1/usercancelpromotion", {
                        User_id: Username,
                        System: Systems,
                        no: index.no,
                    }, {
                        headers: { Authorization: `Bearer ${token}` }
                    }).then(async (res) => {
                        if (res.data.status === 200) {
                            this.checkpro(null, 999);
                            Swal.fire('ดำเนินการเรียบร้อย', '', 'success')
                        }
                        else {
                            Swal.fire('ไม่สามารถดำเนินการได้ โปรดรอซักครู่', '', 'error')
                        }
                    });

                }
            })
        }

    }

    openAccoutcan(evt, cityName) {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("x-menu-account-list-sidebar");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].className = "x-menu-account-list-sidebar";
        }
    }

    async rechecklogin(Username, token) {

        try {
            // console.log(4);
            instance.post("/api/v1/checkpoint", {
                userid: Username,
                System: Systems,
                Function: 'rechecklogin-navbar.js'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                //  console.log(res.data);
                if (res.data.status === 200) {
                    localStorage.setItem('auth_token', res.data.token)
                    this.setState({
                        rankpic: (res.data.tblrank.image == null || res.data.tblrank.image == "") ? "https://betner.co/build/rank/1.jpeg" : (res.data.tblrank.picrack == null || res.data.tblrank.picrack == "") ? "https://betner.co/build/rank/" + res.data.tblrank.image : res.data.tblrank.picrack,
                        rankname: res.data.tblrank.name,
                        rankob: res.data.tblrank,
                        rankpoint: res.data.rankpoint,
                        accnumber: res.data.message.accnumber,
                        userid: res.data.message.member_id,
                        username: res.data.message.playerid,
                        fname: res.data.message.firstname,
                        point: res.data.message.Point,
                        balance: res.data.databalance,
                        loginstate: true,
                    });
                }
                else if (res.data.status === 401) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }

            }).catch(async (err) => {
                console.log(err)
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }
            });
        }
        catch (ex) {
            //////console.log(ex);
        }

    }
    checkpro = async (e, proid) => {
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        await instance.post("/api/v1/checkpromotion", {
            userid: Username,
            System: Systems,
            Proid: proid,
        }, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(async (res) => {
            if (res.data.status === 200) {
                //console.log(res.data)
                // console.log(res.data.message.tblpromotionredeems)
                if (res.data.type === 0) {
                    this.setState({
                        prodetail: res.data.message.tblpromotions,
                    });
                }
                else if (res.data.type === 1) {
                    this.setState({
                        proderedeemlist: res.data.message.tblpromotionredeems,
                    });

                }

            }
            else if (res.data.status === 201) {
                await Swal.fire({
                    title: 'error!',
                    text: 'ท่านไม่เข้าเงื่อนใขโปรโมชั่นนี้',
                    icon: 'error',
                    confirmButtonText: 'ตกลง'
                })
                return false;
            }
            else if (res.data.status === 202) {
                await Swal.fire({
                    title: 'error!',
                    text: 'ท่านใช้โปรโมชั่นค้างไว้ สามารถเติมเงินได้เลย',
                    icon: 'error',
                    confirmButtonText: 'ตกลง'
                })
                return false;
            }

        }).catch(async (err) => {
            // console.log(err);
            if (err.response.status === 401 || err.response.status === 403) {
                localStorage.clear();
                await Swal.fire({
                    title: 'Warning!',
                    text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                    icon: 'warning',
                    confirmButtonText: 'ตกลง'
                })
                window.location.href = '/';
            }
        });
    }


    validateLogout = (e) => {
        localStorage.removeItem('auth_token', '')
        this.setState({
            userid: null,
            username: null,
            password: null,
            firstname: null,
            lastname: null,
            point: null,
            loginstate: false,
        });
    }
    async recheccredit(evt) {

        evt.currentTarget.className += " fa-spin";
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        // this.rechecklogin(Username, token);
        this.setState({
            userid: decoded.message.playerid,
            username: decoded.message.playerid,
            firstname: decoded.message.firstname,
            lastname: decoded.message.lastname,
            loginstate: true,
        });
        try {
            //  console.log(5);
            await instance.post("/api/v1/checkpoint", {
                userid: Username,
                System: Systems,
                Function: 'recheccredit-navbar.js'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                //console.log(res.data);
                if (res.data.status === 200) {
                    localStorage.setItem('auth_token', res.data.token)
                    this.setState({
                        accnumber: res.data.message.accnumber,
                        userid: res.data.message.member_id,
                        username: res.data.message.playerid,
                        fname: res.data.message.firstname,
                        point: res.data.message.Point,
                        balance: res.data.databalance,
                        loginstate: true,
                    });
                    var i, tablinks;
                    tablinks = document.getElementsByClassName("-btn-balance");
                    for (i = 0; i < tablinks.length; i++) {
                        tablinks[i].className = tablinks[i].className.replace(" fa-spin", "");
                    }
                }
                else if (res.data.status === 401) {
                    localStorage.clear();
                    //   console.log(res.data);
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }

            }).catch(async (err) => {
                //    console.log(err);
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }
            });
        }
        catch (ex) {
            //////console.log(ex);
        }
    }
    logout = (e) => {
        // e.preventDefault();
        let errors = this.validateLogout();
        if (errors === true) {
            // alert("You are successfully signed in...");
            window.location.reload()
        } else {

            this.setState({
                errors: errors,
                formSubmitted: true
            });
            window.location.reload()
        }
    }
    render() {

        const { loginstate, userid, username, point, firstname, lastname, balance, proderedeemlist } = this.state
        // //////console.log(this.state);
        return (
            <>
                <nav className="x-header js-header-selector navbar navbar-expand ">
                    <div className="container-fluid -inner-container">
                        <div className="d-none d-lg-block">
                            <button type="button" data-toggle="modal" data-target="#themeSwitcherModal" className="btn bg-transparent p-0 x-hamburger" >
                                <span />
                                <span />
                                <span />
                            </button>
                        </div>
                        <div className="d-lg-none">
                            <div className="x-hamburger js-hamburger-toggle" data-toggle="modal" data-target="#themeSwitcherModal" >
                                <span />
                                <span />
                                <span />
                            </div>
                        </div>
                        <div id="headerBrand">
                            <a className="navbar-brand" href="/">
                                <img className="-logo -default" src="/build/web/ez-bet/img/logo.png?v=1" alt="GPAY88BET" />
                                <img className="-logo -invert" src="/build/web/ez-bet/img/logo-black.png?v=1" alt="GPAY88BET" />
                            </a>
                        </div>
                        <div className="x-menu">
                            <div className="-menu-container">
                                <a href="/listgameall/slot/" className={(this.state.page === "slot") ? "-menu-link slot-tab active" : "-menu-link slot-tab"}>
                                    <span>Slot</span>
                                </a>
                                <a href="/listgameall/casino/" className={(this.state.page === "casino") ? "-menu-link casinos-tab active" : "-menu-link casinos-tab"}>
                                    <span>คาสิโน</span>
                                </a>
                                <a href="/listgameall/sport/" className={(this.state.page === "sport") ? "-menu-link sports-tab active" : "-menu-link sports-tab"}>
                                    <span>สปอร์ต</span>
                                </a>
                                {/* <a href="/listgameall/lotto/" className={(this.state.page === "lotto") ? "-menu-link casinos-tab active" : "-menu-link casinos-tab"}>
                                    <span>หวย</span>
                                </a> */}
                                <a href="/promotions" className={(this.state.page === "promotions") ? "-menu-link promotions-tab active" : "-menu-link promotions-tab"}>
                                    <span>โปรโมชั่น</span>
                                </a>
                                <a href="/article" className={(this.state.page === "article") ? "-menu-link promotions-tab active" : "-menu-link promotions-tab"}>
                                    <span>บทความ</span>
                                </a>
{/* 
                                { !loginstate? "": <a type="button"
                                    href="#account"
                                    data-toggle="modal"
                                    data-target="#accountModal"
                                    className={(this.state.page === "invite") ? "-menu-link promotions-tab active" : "-menu-link promotions-tab"}>
                                    <span>ชวนเพื่อน</span>
                                </a>}
                             */}
                            </div>
                        </div>
                        <div id="headerContent">
                            <div className="d-flex">
                                {/* <div className="x-ranking-entrance" >
                                    <a href="/ranking" className="x-ranking-entrance -link-wrapper ">
                                        <div className="-image-wrapper">
                                            <img className="img-fluid -ic-right-star-rank" src="/build/web/ez-bet/img/ez-slot-ic-star-rank-animation-1.png" alt="GPAY88BET star icon" />
                                        </div>
                                        <div className="-text-wrapper">อันดับ <br /> ผู้ชนะ</div>
                                    </a>
                                </div> */}
                                {(loginstate) ?

                                    <div className="x-logged">
                                        {/* <div className="x-ranking-entrance">
                                            <a href="/checkin" className="-link-wrapper">
                                                <div className="-image-wrapper">
                                                    <img className="img-fluid -ic-right-star-rank" src="/build/web/igame-index-lobby-wm/img/download.png" alt="GPAY88BET star icon" />
                                                </div>
                                                <div className="-text-wrapper">เช๊คอิน </div>
                                            </a>
                                        </div> */}
                                        {/* <div className=" -deposit-container d-none d-lg-block"  style={{paddingRight:"40px"}}>
                                        <a
                                            href="#/"
                                            className="text-white "
                                            data-toggle="modal"
                                            data-target="#wheelgiftspin"
                                        >
                                            <div className="-image-wrapper"  style={{position:"relative"}}>
                                                <img className="  img-fluid -ic-right-star-rank "  width="30px" src="/build/web/igame-index-lobby-wm/img/wheel.png" alt="GPAY88BET star icon" />
                                                <span className=" -menu-link"  style={{position:"absolute",paddingLeft:"5px"}}>กงล้อ</span>
                                            </div>
                                          
                                        </a>
                                    </div> */}
                                        <div className="-deposit-container d-none d-lg-block">
                                            <a
                                                href="#deposit-choose-promotion"
                                                className="text-white js-account-approve-aware btn -btn-deposit"
                                                data-toggle="modal"
                                                data-target="#depositChoosePromotionModal"
                                            >
                                                <div className="f-7">ฝากเงิน</div>
                                            </a>
                                        </div>
                                        <div className="-withdraw-container d-none d-lg-block">
                                            <a
                                                href="#withdraw"
                                                className="text-white js-account-approve-aware btn -btn-withdraw"
                                                data-toggle="modal"
                                                data-target="#withdrawModal"
                                            >
                                                <div className="f-7">ถอนเงิน</div>
                                            </a>
                                        </div>
                                        <div className="-balance-container">

                                            <div className="-text-username">
                                                {this.state.userid}
                                            </div>
                                            <div className="-user-balance js-user-balance f-sm-6 f-7 ">
                                                <div className="-inner-box-wrapper" style={{ fontWeight: "600", borderRadius: "20px", border: "1px solid #ffafa4" }} >
                                                    <span id="customer-balance" className="js-customer-balance">
                                                        <img
                                                            className="img-fluid -ic-coin"
                                                            src="/build/web/igame-index-lobby-wm/img/ic-coin.png"
                                                            alt=""
                                                            width={26}
                                                            height={21}
                                                        />
                                                        <span className="text-green-lighter"><NumericFormat value={balance} displayType={'text'} thousandSeparator={true} /> </span>
                                                    </span>
                                                </div>
                                                <button
                                                    type="button"
                                                    className="-btn-balance"
                                                    id="btn-customer-balance-reload"
                                                    onClick={(e) => {
                                                        this.recheccredit(e)
                                                    }}
                                                    data-target="#customer-balance"
                                                >
                                                    <i className="fas fa-sync-alt f-9" />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="-profile-container ">
                                            <a
                                                href="#account"
                                                data-toggle="modal"
                                                data-target="#accountModal"
                                                className="-btn-wrapper"
                                            >
                                                <div className="x-profile-image">
                                                    <img
                                                        className="img-fluid "
                                                        width={40}
                                                        height={21}
                                                        src={"/build/web/ez-bet/img/icon-4399701_1280.webp"}
                                                        alt="customer image"
                                                    />
                                                </div>
                                            </a>
                                            <div className="d-xl-none d-block">
                                                <div className="js-ez-logged-sidebar">
                                                    <a
                                                        onClick={this.openAccout}
                                                    >
                                                        <div className="x-profile-image">
                                                            <img
                                                                className="img-fluid -profile-image"
                                                                src={this.state.rankpic}
                                                                alt="customer image"
                                                            />
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="x-menu-account-list-sidebar">
                                                    <div className="x-modal-account-menu-mobile">
                                                        <div className="-modal-profile-mobile d-xl-none d-block">
                                                            <div className="text-right">
                                                                <a href="#account" onClick={this.openAccoutcan}>
                                                                    <i className="fas fa-times f-5 js-close-account-sidebar" />
                                                                </a>
                                                            </div>
                                                            <div className="x-profile-image">
                                                                <img
                                                                    className="img-fluid -profile-image"
                                                                    src={this.state.rankpic}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="-balance-container">
                                                                <div className="-text-username">{username}</div>
                                                                <div className="-user-balance js-user-balance f-sm-6 f-7 ">
                                                                    <div className="-inner-box-wrapper" style={{ fontWeight: "600", borderRadius: "20px", border: "1px solid #ffafa4" }}>
                                                                        <span id="customer-balance" className="js-customer-balance">
                                                                            <img
                                                                                className="img-fluid -ic-coin"
                                                                                src="/build/web/igame-index-lobby-wm/img/ic-coin.png"
                                                                                alt=""
                                                                                style={{ marginLeft: "-15px", marginBottom: "3px" }}
                                                                                width={26}
                                                                                height={21}
                                                                            />
                                                                            <span className="text-green-lighter"><NumericFormat value={balance} displayType={'text'} thousandSeparator={true} /> </span>
                                                                        </span>
                                                                    </div>
                                                                    <button
                                                                        type="button"
                                                                        className="-btn-balance"
                                                                        id="btn-customer-balance-reload"
                                                                        onClick={(e) => {
                                                                            this.recheccredit(e)
                                                                        }}
                                                                        data-target="#customer-balance"
                                                                    >
                                                                        <i className="fas fa-sync-alt f-9" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="-transaction-button-wrapper">
                                                            <a
                                                                href="javascript:void(0);"
                                                                className="x-transaction-button-v2 -in-sidebar -deposit js-close-account-sidebar"
                                                                data-toggle="modal"
                                                                data-target="#depositChoosePromotionModal"
                                                            >
                                                                <img
                                                                    className="img-fluid -icon"
                                                                    src="/build/web/ez-bet/img/ez-theme-switcher-ic-deposit.png"
                                                                    alt="ฝากเงิน"
                                                                />
                                                                <div className="-text-wrapper">
                                                                    <span className="-title">ฝากเงิน</span>
                                                                    <span className="-sub-title">Deposit</span>
                                                                </div>
                                                            </a>
                                                            <a
                                                                href="javascript:void(0);"
                                                                className="x-transaction-button-v2 -in-sidebar -withdraw js-close-account-sidebar"
                                                                data-toggle="modal"
                                                                data-target="#withdrawModal"
                                                            >
                                                                <img
                                                                    className="img-fluid -icon"
                                                                    src="/build/web/ez-bet/img/ez-theme-switcher-ic-withdraw.png"
                                                                    alt="ถอนเงิน"
                                                                />
                                                                <div className="-text-wrapper">
                                                                    <span className="-title">ถอนเงิน</span>
                                                                    <span className="-sub-title">Withdraw</span>
                                                                </div>
                                                            </a>
                                                        </div>

                                                        <div className="-lists-outer-wrapper">
                                                            <ul className="navbar-nav">
                                                                <li className="nav-item -account-profile">
                                                                    <button
                                                                        type="button"
                                                                        className="nav-link js-close-account-sidebar "
                                                                        data-toggle="modal"
                                                                        data-target="#accountModalMobile"
                                                                    >
                                                                        <img
                                                                            src="/build/web/igame-index-lobby-wm/img/ic-menu-account-user.png"
                                                                            className="img-fluid -icon-image"
                                                                            alt="ic-menu-account-user"
                                                                        />
                                                                        <span className="-text-menu">ข้อมูลบัญชี</span>
                                                                    </button>
                                                                </li>
                                                                <li className="nav-item -account-provider">
                                                                    <a
                                                                     type="button"
                                                                     className="nav-link js-close-account-sidebar "
                                                                     data-toggle="modal"
                                                                     data-target="#providerUserModalMobile"
                                                                    >
                                                                        <img
                                                                            src="/build/web/igame-index-lobby-wm/img/ic-menu-account-provider.png"
                                                                            className="img-fluid -icon-image"
                                                                            alt="ic-menu-account-provider"
                                                                        />
                                                                        <span className="-text-menu">ชวนเพื่อน</span>
                                                                    </a>
                                                                </li>
                                                                <li className="nav-item -account-provider">
                                                                    <button
                                                                        type="button"
                                                                        className="nav-link js-close-account-sidebar "
                                                                        data-toggle="modal"
                                                                        data-target="#cashoutUserModalMobile"
                                                                    >
                                                                        <img
                                                                            src="/build/web/igame-index-lobby-wm/img/ic-withdraw-heading.png"
                                                                            className="img-fluid -icon-image"
                                                                            alt="ic-menu-account-provider"
                                                                        />
                                                                        <span className="-text-menu">คืนยอดใด้เสีย</span>
                                                                    </button>
                                                                </li>
                                                                <li className="nav-item -menu-item -account-bill-history">
                                                                    <button
                                                                        type="button"
                                                                        className="nav-link js-close-account-sidebar "
                                                                        data-toggle="modal"
                                                                        data-target="#historyModal"
                                                                    >
                                                                        <img
                                                                            alt="เมนูใช้งานของผู็ใช้บนเว็บคาสิโนออนไลน์ สล็อตออนไลน์"
                                                                            className="img-fluid -icon-image"
                                                                            src="/build/web/igame-index-lobby-wm/img/ic-menu-account-bill-history.png?v=5"
                                                                        />
                                                                        <span className="-text-menu">ประวัติทำรายการ</span>
                                                                    </button>
                                                                </li>
                                                                <li className="nav-item -coupon">
                                                                    <button
                                                                        type="button"
                                                                        className="nav-link js-close-account-sidebar js-account-approve-aware"
                                                                        data-toggle="modal"
                                                                        data-target="#couponModalMobile"
                                                                    >
                                                                        <img
                                                                            src="/build/web/igame-index-lobby-wm/img/ic-menu-account-coupon.png"
                                                                            className="img-fluid -icon-image"
                                                                            alt="ic-menu-account-coupon"
                                                                        />
                                                                        <span className="-text-menu">ใช้คูปอง</span>
                                                                    </button>
                                                                </li>
                                                                <li className="nav-item -join-promotion" >
                                                                    <button
                                                                        type="button"
                                                                        onClick={(e) => {
                                                                            this.checkpro(e, 999)
                                                                        }}
                                                                        data-toggle="modal"
                                                                        data-target="#joinPromotionModalMobile"
                                                                        className="nav-link js-close-account-sidebar "

                                                                    >
                                                                        <img
                                                                            src="/build/web/igame-index-lobby-wm/img/ic-menu-account-promotion.png"
                                                                            className="img-fluid -icon-image"
                                                                            alt="ic-menu-account-promotion"
                                                                        />
                                                                        <span className="-text-menu">โปรโมชั่นที่เข้าร่วม</span>
                                                                    </button>
                                                                </li>
                                                                <li className="nav-item -logout">
                                                                    <a
                                                                        href="/logout"
                                                                        className="nav-link js-require-confirm"
                                                                        data-title="ต้องการออกจากระบบ ?"
                                                                    >
                                                                        <img
                                                                            className="img-fluid -icon-image"
                                                                            src="/build/web/igame-index-lobby-wm/img/ic-menu-account-logout.png"
                                                                            alt="ic-menu-account-logout"
                                                                        />
                                                                        <span className="-text-menu">ออกจากระบบ</span>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="-overlay" />
                                                </div>
                                            </div>
                                        </div>

                                        {/* <a href="#account" data-toggle="modal" data-target="#accountModal">
                                            <div className="x-profile-image">
                                                <img
                                                    className="img-fluid -profile-image"
                                                    src={"/build/rank/" + this.state.rankpic}
                                                    alt=""
                                                />
                                            </div>
                                        </a> */}

                                    </div>

                                    :
                                    <div className="x-logged">
                                        <a
                                            href={this.state.linetelegramlink}
                                            className="x-header-btn-support -in-logged xtelegram "
                                            target="_blank"
                                            rel="noreferrer nofollow"
                                            style={{ marginRight: "7px", marginTop: "5px" }}
                                        >
                                            <picture>
                                                <source
                                                    type="image/webp"
                                                    srcSet="/build/web/ez-bet/img/ic-telegram-support.webp?v=3"
                                                />
                                                <source
                                                    type="image/png?v=1"
                                                    srcSet="/build/web/ez-bet/img/ic-telegram-support.png?v=1"
                                                />
                                                <img
                                                    alt="GPAY88BET"
                                                    className="img-fluid -ic"
                                                    width={125}
                                                    height={40}
                                                    src="/build/web/ez-bet/img/ic-telegram-support.png?v=1"
                                                />
                                            </picture>
                                            <picture>
                                                <source
                                                    type="image/webp"
                                                    srcSet="/build/web/ez-bet-v2/img/ic-telegram-support-mobile.png?v=1"
                                                />
                                                <source
                                                    type="image/png?v=1"
                                                    srcSet="/build/web/ez-bet-v2/img/ic-telegram-support-mobile.png?v=1"
                                                />
                                                <img
                                                    alt="GPAY88BET"
                                                    className="img-fluid -ic -mobile"
                                                    width={42}
                                                    height={42}
                                                    src="/build/web/ez-bet-v2/img/ic-telegram-support-mobile.png?v=1"
                                                />
                                            </picture>
                                        </a>
                                        <a
                                            href={this.state.linelink}
                                            className="x-header-btn-support -in-logged "
                                            target="_blank"
                                            rel="noreferrer nofollow"
                                            style={{ marginTop: "5px" }}
                                        >
                                            <picture>
                                                <source
                                                    type="image/webp"
                                                    srcSet="/build/web/ez-bet/img/ic-line-support.webp?v=3"
                                                />
                                                <source
                                                    type="image/png?v=1"
                                                    srcSet="/build/web/ez-bet/img/ic-line-support.png?v=1"
                                                />
                                                <img
                                                    alt="GPAY88BET"
                                                    className="img-fluid -ic"
                                                    width={125}
                                                    height={40}
                                                    src="/build/web/ez-bet/img/ic-line-support.png?v=1"
                                                />
                                            </picture>
                                            <picture>
                                                <source
                                                    type="image/webp"
                                                    srcSet="/build/web/ez-bet-v2/img/ic-line-support-mobile.png?v=1"
                                                />
                                                <source
                                                    type="image/png?v=1"
                                                    srcSet="/build/web/ez-bet-v2/img/ic-line-support-mobile.png?v=1"
                                                />
                                                <img
                                                    alt="GPAY88BET"
                                                    className="img-fluid -ic -mobile"
                                                    width={42}
                                                    height={42}
                                                    src="/build/web/ez-bet-v2/img/ic-line-support-mobile.png?v=1"
                                                />
                                            </picture>
                                        </a>
                                        <div>
                                            <button href="#loginModal" style={{ marginTop: "7px" }} className="-btn-header-login btn f-sm-6 f-7" data-toggle="modal" data-target="#themeSwitcherModal">
                                                เข้าสู่ระบบ
                                            </button>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>

                </nav>
                <div
                    className="x-modal modal -v2 show"
                    id="joinPromotionModalMobile"
                    tabIndex={-1}
                    role="dialog"
                    data-container="#joinPromotionModalMobile"
                    aria-modal="true"
                >
                    <div
                        className="modal-dialog -modal-size  modal-dialog-centered modal-dialog-scrollable -modal-mobile -no-fixed-button -no-padding-x"
                        role="document"
                    >
                        <div className="modal-content -modal-content">
                            <button
                                type="button"
                                className="close f-1 "
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <i className="fas fa-times" />
                            </button>
                            <div className="modal-header -modal-header">
                                <div className="x-modal-mobile-header">
                                    <div className="-header-mobile-container">
                                        <h3 className="x-title-modal mx-auto text-center d-inline-block">
                                            การเข้าร่วมโปรโมชั่น
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-body -modal-body">
                                <div className="-outer-history-wrapper">
                                    <div className="x-bill-history-container">
                                        <div
                                            className="wg-container wg-container__wg_bill_history wg--loaded"
                                            data-widget-name="wg_bill_history"
                                            data-widget-options='{"script_path":null,"style_path":null,"image_path":null,"visibility":"away","visibility_offset":"100%","render_url":"\/_widget","render_method":"GET","attr_style":null,"attr_class":null,"scroll_position":"current","options":{},"callback":{},"mode":"clear","mask_mode":"over","mask_style":"wg-loading","limit":20,"page":1,"template":"@Base\/Widget\/billHistory.html.twig","name":"wg_bill_history"}'
                                            data-widget-user-options='{"page":1}'
                                        >
                                            <div className="wg-content" style={{ display: (proderedeemlist.length === 0) ? "" : "none" }}>
                                                <div className="-no-result-wrapper mt-3">
                                                    <div className="-heading-title">
                                                        <i className="fas fa-times-circle -icon" />
                                                        <div className="-title">ไม่พบข้อมูล</div>
                                                        <div className="-sub-title">คุณยังไม่มีข้อมูลการทำรายการค่ะ</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wg-content" style={{ display: (proderedeemlist.length === 0) ? "none" : "" }}>
                                                <table className="table table-borderless table-striped">
                                                    <tbody>
                                                        {proderedeemlist.map((listitem, i) => (
                                                            <tr key={i} id={i}>
                                                                <td className="-description-body-wrapper">
                                                                    <div className="-title-wrapper">
                                                                        <span className="-title">{listitem.proname}</span>
                                                                        <span style={{ display: (listitem.status === 1) ? "" : "none", margin: "5px" }}>
                                                                            <a href="#0"
                                                                                onClick={(e) => {
                                                                                    this.cancelredeempromotion(e, listitem)
                                                                                }}
                                                                                className="-link-change-password" ><u>ยกเลิก</u></a>
                                                                        </span>
                                                                    </div>
                                                                    <div className="-state-wrapper" style={{ display: (listitem.status === 1) ? "" : "none" }}>
                                                                        <span className="-state-text">สถานะ : </span>
                                                                        <i className="fas fa-spinner fa-spin"></i>
                                                                        <span className="-state-title">รอดำเนินการ</span>
                                                                        <span className="-state-title -short">รอดำเนินการ  </span>
                                                                    </div>
                                                                    <div className="-state-wrapper" style={{ display: (listitem.status === 4) ? "" : "none" }}>
                                                                        <span className="-state-text">สถานะ : </span>
                                                                        <img
                                                                            src="https://asset.cloudigame.co/build/admin/img/wt_theme/popular_3/ic_fail.png"
                                                                            className="-ic"
                                                                            alt=""
                                                                        />
                                                                        <span className="-state-title">ยกเลิก (ยกเลิกโดยผู้เล่น)</span>
                                                                        <span className="-state-title -short">ยกเลิก (โดยผู้เล่น)</span>
                                                                    </div>
                                                                    <div className="-state-wrapper" style={{ display: (listitem.status === 3) ? "" : "none" }}>
                                                                        <span className="-state-text">สถานะ : </span>
                                                                        <img
                                                                            src="https://asset.cloudigame.co/build/admin/img/wt_theme/popular_3/ic_fail.png"
                                                                            className="-ic"
                                                                            alt=""
                                                                        />
                                                                        <span className="-state-title">ไม่สำเร็จ (โดยระบบ)</span>
                                                                        <span className="-state-title -short">ไม่สำเร็จ (โดยระบบ)</span>
                                                                    </div>
                                                                    <div className="-state-wrapper" style={{ display: (listitem.status === 2) ? "" : "none" }}>
                                                                        <span className="-state-text">สถานะ : </span>
                                                                        <img
                                                                            src="https://asset.cloudigame.co/build/admin/img/wt_theme/popular_3/ic_success.png"
                                                                            className="-ic"
                                                                            alt=""
                                                                        />
                                                                        <span className="-state-title">สำเร็จ</span>
                                                                        <span className="-state-title -short">สำเร็จ</span>
                                                                    </div>
                                                                </td>
                                                                <td className="-transaction-body-wrapper">
                                                                    <div className="-amount -deposit">{listitem.amount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} เครดิต</div>
                                                                    <div className="-datetime">{moment(listitem.datecreate).format('DD/MM/YY') + " - " + moment(listitem.datecreate).format('HH:mm') + " น."} </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="x-modal modal -v2 x-theme-switcher-v2"
                    id="themeSwitcherModal"
                    role="dialog"
                    data-loading-container=".js-modal-content"
                    data-ajax-modal-always-reload="true"
                    tabIndex={-1}
                    aria-modal="true"
                >
                    <div
                        className="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable modal-dialog-centered"
                        role="document"
                    >
                        <div className="modal-content -modal-content">
                            <button
                                type="button"
                                className="close f-1 "
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <i className="fas fa-times" />
                            </button>
                            <div className="modal-body -modal-body">
                                <div className="-theme-switcher-container">
                                    <div className="-inner-header-section">
                                        <a className="-link-wrapper" href="/">
                                            <picture>
                                                <img
                                                    alt="EZ Casino รวมคาสิโนออนไลน์ บาคาร่าสด สล็อต กีฬา และเครดิตฟรี"
                                                    className="-logo lazyloaded"
                                                    data-src="/build/web/ez-bet/img/logo.png?v=1"
                                                    src="/build/web/ez-bet/img/logo.png?v=1"
                                                />
                                            </picture>
                                        </a>
                                    </div>
                                    <div className="-inner-top-body-section" style={{ display: this.state.loginstate ? "none" : "" }}>
                                        <div className="col-6 -wrapper-box">
                                            <button
                                                type="button"
                                                className="btn -btn-item -top-btn -register-button "
                                                data-toggle="modal"
                                                data-dismiss="modal"
                                                data-target="#registerModal"
                                            >
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        data-srcset="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-register.webp?v=3"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-register.webp?v=3"
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        data-srcset="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-register.png?v=1"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-register.png?v=1"
                                                    />
                                                    <img
                                                        alt="EZ Casino รูปไอคอนสมัครสมาชิก"
                                                        className="img-fluid -icon-image lazyloaded"
                                                        data-src="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-register.png?v=1"
                                                        src="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-register.png?v=1"
                                                    />
                                                </picture>
                                                <div className="-typo-wrapper">
                                                    <div className="-typo ">สมัครเลย</div>
                                                </div>
                                            </button>
                                        </div>
                                        <div className="col-6 -wrapper-box">
                                            <button
                                                type="button"
                                                className="btn -btn-item -top-btn -login-btn "
                                                data-toggle="modal"
                                                data-dismiss="modal"
                                                data-target="#loginModal"
                                            >
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        data-srcset="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-login.webp?v=3"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-login.webp?v=3"
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        data-srcset="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-login.png?v=1"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-login.png?v=1"
                                                    />
                                                    <img
                                                        alt="EZ Casino รูปไอคอนเข้าสู่ระบบ"
                                                        className="img-fluid -icon-image lazyloaded"
                                                        data-src="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-login.png?v=1"
                                                        src="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-login.png?v=1"
                                                    />
                                                </picture>
                                                <div className="-typo-wrapper">
                                                    <div className="-typo ">เข้าสู่ระบบ</div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="-inner-top-body-section" style={{ display: this.state.loginstate ? "" : "none" }}>
                                        <div className="col-6 -wrapper-box">
                                            <button
                                                type="button"
                                                className="btn -btn-item -top-btn -deposit-btn "
                                                data-toggle="modal"
                                                data-dismiss="modal"
                                                data-target="#depositChoosePromotionModal"
                                            >
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        data-srcset="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-register.webp?v=3"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-register.webp?v=3"
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        data-srcset="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-register.png?v=1"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-register.png?v=1"
                                                    />
                                                    <img
                                                        alt="EZ Casino รูปไอคอนสมัครสมาชิก"
                                                        className="img-fluid -icon-image lazyloaded"
                                                        data-src="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-register.png?v=1"
                                                        src="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-register.png?v=1"
                                                    />
                                                </picture>
                                                <div className="-typo-wrapper">
                                                    <div className="-typo ">ฝากเงิน</div>
                                                </div>
                                            </button>
                                        </div>
                                        <div className="col-6 -wrapper-box">
                                            <button
                                                type="button"
                                                className="btn -btn-item -top-btn -withdraw-btn "
                                                data-toggle="modal"
                                                data-dismiss="modal"
                                                data-target="#withdrawModal"
                                            >
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        data-srcset="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-login.webp?v=3"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-login.webp?v=3"
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        data-srcset="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-login.png?v=1"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-login.png?v=1"
                                                    />
                                                    <img
                                                        alt="EZ Casino รูปไอคอนเข้าสู่ระบบ"
                                                        className="img-fluid -icon-image lazyloaded"
                                                        data-src="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-login.png?v=1"
                                                        src="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-login.png?v=1"
                                                    />
                                                </picture>
                                                <div className="-typo-wrapper">
                                                    <div className="-typo ">ถอนเงิน</div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="-inner-center-body-section">
                                        <div className="col-6 -wrapper-box">
                                            <a
                                                href="/event"
                                                className="btn -btn-item -event-button -menu-center -horizontal"
                                            >
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        data-srcset="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-event.webp?v=3"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-event.webp?v=3"
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        data-srcset="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-event.png?v=1"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-event.png?v=1"
                                                    />
                                                    <img
                                                        alt="EZ Casino รูปไอคอนสิทธิพิเศษ"
                                                        className="img-fluid -icon-image lazyloaded"
                                                        data-src="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-event.png?v=1"
                                                        src="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-event.png?v=1"
                                                    />
                                                </picture>
                                                <div className="-typo-wrapper">
                                                    <div className="-typo ">สิทธิพิเศษ</div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-6 -wrapper-box">
                                            <a
                                                href="/promotions"
                                                className="btn -btn-item -promotion-button -menu-center -horizontal"
                                            >
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        data-srcset="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-promotion.webp?v=3"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-promotion.webp?v=3"
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        data-srcset="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-promotion.png?v=1"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-promotion.png?v=1"
                                                    />
                                                    <img
                                                        alt="EZ Casino รูปไอคอนโปรโมชั่น"
                                                        className="img-fluid -icon-image lazyloaded"
                                                        data-src="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-promotion.png?v=1"
                                                        src="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-promotion.png?v=1"
                                                    />
                                                </picture>
                                                <div className="-typo-wrapper">
                                                    <div className="-typo ">โปรโมชั่น</div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-6 -wrapper-box">
                                            <a
                                                href="#"
                                                className="btn -btn-item -movie-button -menu-center -horizontal"
                                                target="_blank"
                                                rel="noopener nofollow"
                                            >
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        data-srcset="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-movie.webp?v=3"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-movie.webp?v=3"
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        data-srcset="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-movie.png?v=1"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-movie.png?v=1"
                                                    />
                                                    <img
                                                        alt="EZ Casino รูปไอคอนดูหนัง"
                                                        className="img-fluid -icon-image lazyloaded"
                                                        data-src="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-movie.png?v=1"
                                                        src="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-movie.png?v=1"
                                                    />
                                                </picture>
                                                <div className="-typo-wrapper">
                                                    <div className="-typo ">ดูหนัง</div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-6 -wrapper-box">
                                            <a
                                                href={this.state.linelink}
                                                className="btn -btn-item -line-button -menu-center -horizontal"
                                                target="_blank"
                                                rel="noopener nofollow"
                                            >
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        data-srcset="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-line.webp?v=3"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-line.webp?v=3"
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        data-srcset="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-line.png?v=1"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-line.png?v=1"
                                                    />
                                                    <img
                                                        alt="EZ Casino รูปไอคอนดูหนัง"
                                                        className="img-fluid -icon-image lazyloaded"
                                                        data-src="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-line.png?v=1"
                                                        src="/build/web/ez-bet-v2/img/ez-theme-switcher-ic-line.png?v=1"
                                                    />
                                                </picture>
                                                <div className="-typo-wrapper">
                                                    <div className="-typo ">Line</div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="-inner-bottom-body-section">
                                        <div className="col-12 -wrapper-box">
                                            <a
                                                href={this.state.linelink}
                                                className="btn -btn-item -social-share-button -menu-banner "
                                            >
                                                <img
                                                    alt="EZ Casino รูปแบนเนอร์แชร์แลกทรัพย์"
                                                    className="img-fluid -banner-image"
                                                    src="/build/web/ez-bet-v2/img/ez-theme-switcher-banner-social-share.png?v=1"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}
export default App;
