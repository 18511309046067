
import React, { Component } from 'react'; //different
import instance from "./axios-instance";
import Systems from "./SystemFunction";
import jwt_decode from "jwt-decode";
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            activePage: 1,
            isActive: false,
            rewardlist: [],
            activeTab: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: true, // Indicates in progress state of login form
            pagecount: 1,
            username: null,
            userid: null,
            point: 0,
            balance: 0,
            products_page_count: 1,
            loginstate: false,
            firstname: null,
            lastname: null,
            alert: null,
            modalOpen: false,
            handleClose: false,
            token: window.localStorage.getItem("token"),
            type: "",
            amount: "",
            remark: "",
            name: "",
            password: "",
            linelink: "",
            page: "",
            left: false,
            changepassword: false,
        }
    }
    async componentDidMount() {

        const search = window.location.href;
        const params = new URLSearchParams(search);
        const space = search.split("/")
        this.setState({
            page: space[4].replace("#", ""),
            pathname: window.location.pathname.replace("/", "").trim()
        });
        try {
            await instance.post("/api/v1/mainsetting", {
                System: Systems,
            }).then((res) => {
                if (res.data.status === 200) {
                    const datas = res.data.message;
                    this.setState({
                        linelink: datas[1].value
                    });
                }
                else {
                    // this.CheckThisGoalError();
                }
            });
        }
        catch (error) {
            //////console.log();
        }
    }

    render() {
        const pathname = this.state.pathname
        const { loginstate, page } = this.state
        return (
            <div>
                {(page === "" || page === "slot" || page === "arcade" || page === "fishing"|| page === "skillgame"|| page === "gamehit"|| page === "newgame"|| page === "providor") ?
                                     <div className="x-cover x-category-cover-container">
                                     <img
                                         src="/build/web/ez-bet-v2/img/cover-slot-bg.png"
                                         className="-bg"
                                         alt="ezslot มีมากกว่า แต่ง่ายกว่า เกมเยอะ ภาพสวย ทั้งใหม่"
                                     />
                                     <img
                                         src="/build/web/ez-bet-v2/img/cover-slot-bg-mobile.png"
                                         className="-bg -mobile"
                                         alt="ezslot มีมากกว่า แต่ง่ายกว่า เกมเยอะ ภาพสวย ทั้งใหม่"
                                     />
                                     <div className="container-fluid h-100">
                                         <div className="row -contents-wrapper">
                                             <div className="col-12 col-md-6 -left-content" />
                                             <div className="col-12 col-md-6 -right-content">
                                                 <div
                                                     data-slickable='{"fade":true,"arrows":false,"autoplay":true,"infinite":true,"pauseOnHover":false,"slidesToShow":1,"autoplaySpeed":3000,"speed":300}'
                                                     data-animatable="fadeInUp"
                                                     data-delay={500}
                                                     className="slick-initialized slick-slider animated fadeInUp"
                                                 >
                                                     <div className="slick-list draggable">
                                                         <div className="slick-track" style={{ opacity: 1, width: 2255 }}>
                                                             <div
                                                                 className="-inner-heading-wrapper slick-slide"
                                                                 data-slick-index={0}
                                                                 aria-hidden="true"
                                                                 style={{
                                                                     width: 451,
                                                                     position: "relative",
                                                                     left: 0,
                                                                     top: 0,
                                                                     zIndex: 998,
                                                                     opacity: 0,
                                                                     transition: "opacity 300ms"
                                                                 }}
                                                                 tabIndex={-1}
                                                             >
                                                                 <h1 className="-title">
                                                                     Slot Online รวมเกมที่โด่งดัง
                                                                     <br className="d-none d-md-block" />
                                                                     ที่สุดในโลกไว้ที่นี่ รับประกันความมันส์
                                                                 </h1>
                                                                 <p className="-sub-title">
                                                                     เล่นง่าย จ่ายจริง นักเดิมพัน Slot Online
                                                                     ไม่ควรพลาดร่วมสนุกบน <br className="d-none d-md-block" />{" "}
                                                                     ezslot เปิดให้บริการ 24 ชม.
                                                                 </p>
                                                                 <div
                                                                     className="-btn-wrapper d-none d-lg-inline-block animated fadeInUp"
                                                                     data-animatable="fadeInUp"
                                                                     data-delay={500}
                                                                 >
                                                                     <a
                                                                         href="javascript:void(0)"
                                                                         className="btn -register-btn order-0"
                                                                         data-toggle="modal"
                                                                         data-target="#registerModal"
                                                                         tabIndex={-1}
                                                                     >
                                                                         <img
                                                                             src="/build/web/ez-bet-v2/img/btn-register-bg.png"
                                                                             className="-btn-bg"
                                                                             alt="EZ Slot สมัครสมาชิก"
                                                                             width={312}
                                                                             height={73}
                                                                         />
                                                                         <span className="-btn-title">สมัครสมาชิก</span>
                                                                     </a>
                                                                 </div>
                                                             </div>
                                                             <div
                                                                 className="-inner-heading-wrapper slick-slide"
                                                                 data-slick-index={1}
                                                                 aria-hidden="true"
                                                                 style={{
                                                                     width: 451,
                                                                     position: "relative",
                                                                     left: "-451px",
                                                                     top: 0,
                                                                     zIndex: 998,
                                                                     opacity: 0,
                                                                     transition: "opacity 300ms"
                                                                 }}
                                                                 tabIndex={-1}
                                                             >
                                                                 <h2 className="-title">
                                                                     ระบบที่มีประสิทธิภาพสูงสุด รวดเร็ว{" "}
                                                                     <br className="d-none d-md-block" /> สะดวกสบาย
                                                                     เพียงคลิกเดียว
                                                                 </h2>
                                                                 <p className="-sub-title">
                                                                     ezslot เว็บเดิมพันออนไลน์อันดับ 1
                                                                     ที่มาพร้อมกับระบบที่ยอดเยี่ยมที่สุด
                                                                     <br className="d-none d-md-block" />
                                                                     ของเว็บพนันออนไลน์ทั่วประเทศ
                                                                 </p>
                                                                 <div
                                                                     className="-btn-wrapper d-none d-lg-inline-block animated fadeInUp"
                                                                     data-animatable="fadeInUp"
                                                                     data-delay={500}
                                                                 >
                                                                     <a
                                                                         href="javascript:void(0)"
                                                                         className="btn -register-btn order-0"
                                                                         data-toggle="modal"
                                                                         data-target="#registerModal"
                                                                         tabIndex={-1}
                                                                     >
                                                                         <img
                                                                             src="/build/web/ez-bet-v2/img/btn-register-bg.png"
                                                                             className="-btn-bg"
                                                                             alt="EZ Slot สมัครสมาชิก"
                                                                             width={312}
                                                                             height={73}
                                                                         />
                                                                         <span className="-btn-title">สมัครสมาชิก</span>
                                                                     </a>
                                                                 </div>
                                                             </div>
                                                             <div
                                                                 className="-inner-heading-wrapper slick-slide"
                                                                 data-slick-index={2}
                                                                 aria-hidden="true"
                                                                 style={{
                                                                     width: 451,
                                                                     position: "relative",
                                                                     left: "-902px",
                                                                     top: 0,
                                                                     zIndex: 998,
                                                                     opacity: 0,
                                                                     transition: "opacity 300ms"
                                                                 }}
                                                                 tabIndex={-1}
                                                             >
                                                                 <h2 className="-title">
                                                                     รองรับทุกระบบทั้ง มือถือ และคอม{" "}
                                                                     <br className="d-none d-md-block" /> Slot Online
                                                                     เจ้าแรกในไทย
                                                                 </h2>
                                                                 <p className="-sub-title">
                                                                     ร่วมสนุกกับ slot online ได้ทุกระบบไม่ว่าที่ไหน เมื่อไหร่{" "}
                                                                     <br className="d-none d-md-block" />{" "}
                                                                     ก็สนุกได้เต็มที่มีเพียงอินเตอร์เน็ตอย่างเดียวก็เล่นได้
                                                                 </p>
                                                                 <div
                                                                     className="-btn-wrapper d-none d-lg-inline-block animated fadeInUp"
                                                                     data-animatable="fadeInUp"
                                                                     data-delay={500}
                                                                 >
                                                                     <a
                                                                         href="javascript:void(0)"
                                                                         className="btn -register-btn order-0"
                                                                         data-toggle="modal"
                                                                         data-target="#registerModal"
                                                                         tabIndex={-1}
                                                                     >
                                                                         <img
                                                                             src="/build/web/ez-bet-v2/img/btn-register-bg.png"
                                                                             className="-btn-bg"
                                                                             alt="EZ Slot สมัครสมาชิก"
                                                                             width={312}
                                                                             height={73}
                                                                         />
                                                                         <span className="-btn-title">สมัครสมาชิก</span>
                                                                     </a>
                                                                 </div>
                                                             </div>
                                                             <div
                                                                 className="-inner-heading-wrapper slick-slide"
                                                                 data-slick-index={3}
                                                                 aria-hidden="true"
                                                                 style={{
                                                                     width: 451,
                                                                     position: "relative",
                                                                     left: "-1353px",
                                                                     top: 0,
                                                                     zIndex: 998,
                                                                     opacity: 0,
                                                                     transition: "opacity 300ms"
                                                                 }}
                                                                 tabIndex={-1}
                                                             >
                                                                 <h2 className="-title">
                                                                     โปรโมชั่นสล็อตออนไลน์ สุดฮอต{" "}
                                                                     <br className="d-none d-md-block" />{" "}
                                                                     โดนใจนักเดิมพันทั้งเก่าและใหม่
                                                                 </h2>
                                                                 <p className="-sub-title">
                                                                     เตรียมรับแรงกระแทกกับโปรโมชั่น slot พิเศษกว่าใครที่เติมเต็ม
                                                                     <br className="d-none d-md-block" />
                                                                     แก่นักเดิมพันทั้งเก่าและใหม่
                                                                 </p>
                                                                 <div
                                                                     className="-btn-wrapper d-none d-lg-inline-block animated fadeInUp"
                                                                     data-animatable="fadeInUp"
                                                                     data-delay={500}
                                                                 >
                                                                     <a
                                                                         href="javascript:void(0)"
                                                                         className="btn -register-btn order-0"
                                                                         data-toggle="modal"
                                                                         data-target="#registerModal"
                                                                         tabIndex={-1}
                                                                     >
                                                                         <img
                                                                             src="/build/web/ez-bet-v2/img/btn-register-bg.png"
                                                                             className="-btn-bg"
                                                                             alt="EZ Slot สมัครสมาชิก"
                                                                             width={312}
                                                                             height={73}
                                                                         />
                                                                         <span className="-btn-title">สมัครสมาชิก</span>
                                                                     </a>
                                                                 </div>
                                                             </div>
                                                             <div
                                                                 className="-inner-heading-wrapper slick-slide slick-current slick-active"
                                                                 data-slick-index={4}
                                                                 aria-hidden="false"
                                                                 style={{
                                                                     width: 451,
                                                                     position: "relative",
                                                                     left: "-1804px",
                                                                     top: 0,
                                                                     zIndex: 999,
                                                                     opacity: 1
                                                                 }}
                                                             >
                                                                 <h2 className="-title">
                                                                     รับประกัน แตกง่าย จ่ายหนัก{" "}
                                                                     <br className="d-none d-md-block" />{" "}
                                                                     กับแจ็กพอตของเกมสล็อตออนไลน์
                                                                 </h2>
                                                                 <p className="-sub-title">
                                                                     เล่นง่าย แตกง่าย ทุกเกมไม่รู้จบ ต้องเกม สล็อตออนไลน์{" "}
                                                                     <br className="d-none d-md-block" />{" "}
                                                                     ที่นี่ที่เดียวรับประกันแตกแจกรางวัลใหญ่ทุกเกมแน่นอน
                                                                 </p>
                                                                 <div
                                                                     className="-btn-wrapper d-none d-lg-inline-block animated fadeInUp"
                                                                     data-animatable="fadeInUp"
                                                                     data-delay={500}
                                                                 >
                                                                     <a
                                                                         href="javascript:void(0)"
                                                                         className="btn -register-btn order-0"
                                                                         data-toggle="modal"
                                                                         data-target="#registerModal"
                                                                         tabIndex={0}
                                                                     >
                                                                         <img
                                                                             src="/build/web/ez-bet-v2/img/btn-register-bg.png"
                                                                             className="-btn-bg"
                                                                             alt="EZ Slot สมัครสมาชิก"
                                                                             width={312}
                                                                             height={73}
                                                                         />
                                                                         <span className="-btn-title">สมัครสมาชิก</span>
                                                                     </a>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                     </div>
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                    : ""
                }
                {
                    (page === "casino") ?
                    <div className="x-cover x-category-cover-container">
                    <img
                        src="/build/web/ez-bet-v2/img/cover-slot-bg.png"
                        className="-bg"
                        alt="ezslot มีมากกว่า แต่ง่ายกว่า เกมเยอะ ภาพสวย ทั้งใหม่"
                    />
                    <img
                        src="/build/web/ez-bet-v2/img/cover-slot-bg-mobile.png"
                        className="-bg -mobile"
                        alt="ezslot มีมากกว่า แต่ง่ายกว่า เกมเยอะ ภาพสวย ทั้งใหม่"
                    />
                    <div className="container-fluid h-100">
                        <div className="row -contents-wrapper">
                            <div className="col-12 col-md-6 -left-content" />
                            <div className="col-12 col-md-6 -right-content">
                                <div
                                    data-slickable='{"fade":true,"arrows":false,"autoplay":true,"infinite":true,"pauseOnHover":false,"slidesToShow":1,"autoplaySpeed":3000,"speed":300}'
                                    data-animatable="fadeInUp"
                                    data-delay={500}
                                    className="slick-initialized slick-slider animated fadeInUp"
                                >
                                    <div className="slick-list draggable">
                                        <div className="slick-track" style={{ opacity: 1, width: 2255 }}>
                                            <div
                                                className="-inner-heading-wrapper slick-slide"
                                                data-slick-index={0}
                                                aria-hidden="true"
                                                style={{
                                                    width: 451,
                                                    position: "relative",
                                                    left: 0,
                                                    top: 0,
                                                    zIndex: 998,
                                                    opacity: 0,
                                                    transition: "opacity 300ms"
                                                }}
                                                tabIndex={-1}
                                            >
                                                <h1 className="-title">
                                                    Slot Online รวมเกมที่โด่งดัง
                                                    <br className="d-none d-md-block" />
                                                    ที่สุดในโลกไว้ที่นี่ รับประกันความมันส์
                                                </h1>
                                                <p className="-sub-title">
                                                    เล่นง่าย จ่ายจริง นักเดิมพัน Slot Online
                                                    ไม่ควรพลาดร่วมสนุกบน <br className="d-none d-md-block" />{" "}
                                                    ezslot เปิดให้บริการ 24 ชม.
                                                </p>
                                                <div
                                                    className="-btn-wrapper d-none d-lg-inline-block animated fadeInUp"
                                                    data-animatable="fadeInUp"
                                                    data-delay={500}
                                                >
                                                    <a
                                                        href="javascript:void(0)"
                                                        className="btn -register-btn order-0"
                                                        data-toggle="modal"
                                                        data-target="#registerModal"
                                                        tabIndex={-1}
                                                    >
                                                        <img
                                                            src="/build/web/ez-bet-v2/img/btn-register-bg.png"
                                                            className="-btn-bg"
                                                            alt="EZ Slot สมัครสมาชิก"
                                                            width={312}
                                                            height={73}
                                                        />
                                                        <span className="-btn-title">สมัครสมาชิก</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div
                                                className="-inner-heading-wrapper slick-slide"
                                                data-slick-index={1}
                                                aria-hidden="true"
                                                style={{
                                                    width: 451,
                                                    position: "relative",
                                                    left: "-451px",
                                                    top: 0,
                                                    zIndex: 998,
                                                    opacity: 0,
                                                    transition: "opacity 300ms"
                                                }}
                                                tabIndex={-1}
                                            >
                                                <h2 className="-title">
                                                    ระบบที่มีประสิทธิภาพสูงสุด รวดเร็ว{" "}
                                                    <br className="d-none d-md-block" /> สะดวกสบาย
                                                    เพียงคลิกเดียว
                                                </h2>
                                                <p className="-sub-title">
                                                    ezslot เว็บเดิมพันออนไลน์อันดับ 1
                                                    ที่มาพร้อมกับระบบที่ยอดเยี่ยมที่สุด
                                                    <br className="d-none d-md-block" />
                                                    ของเว็บพนันออนไลน์ทั่วประเทศ
                                                </p>
                                                <div
                                                    className="-btn-wrapper d-none d-lg-inline-block animated fadeInUp"
                                                    data-animatable="fadeInUp"
                                                    data-delay={500}
                                                >
                                                    <a
                                                        href="javascript:void(0)"
                                                        className="btn -register-btn order-0"
                                                        data-toggle="modal"
                                                        data-target="#registerModal"
                                                        tabIndex={-1}
                                                    >
                                                        <img
                                                            src="/build/web/ez-bet-v2/img/btn-register-bg.png"
                                                            className="-btn-bg"
                                                            alt="EZ Slot สมัครสมาชิก"
                                                            width={312}
                                                            height={73}
                                                        />
                                                        <span className="-btn-title">สมัครสมาชิก</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div
                                                className="-inner-heading-wrapper slick-slide"
                                                data-slick-index={2}
                                                aria-hidden="true"
                                                style={{
                                                    width: 451,
                                                    position: "relative",
                                                    left: "-902px",
                                                    top: 0,
                                                    zIndex: 998,
                                                    opacity: 0,
                                                    transition: "opacity 300ms"
                                                }}
                                                tabIndex={-1}
                                            >
                                                <h2 className="-title">
                                                    รองรับทุกระบบทั้ง มือถือ และคอม{" "}
                                                    <br className="d-none d-md-block" /> Slot Online
                                                    เจ้าแรกในไทย
                                                </h2>
                                                <p className="-sub-title">
                                                    ร่วมสนุกกับ slot online ได้ทุกระบบไม่ว่าที่ไหน เมื่อไหร่{" "}
                                                    <br className="d-none d-md-block" />{" "}
                                                    ก็สนุกได้เต็มที่มีเพียงอินเตอร์เน็ตอย่างเดียวก็เล่นได้
                                                </p>
                                                <div
                                                    className="-btn-wrapper d-none d-lg-inline-block animated fadeInUp"
                                                    data-animatable="fadeInUp"
                                                    data-delay={500}
                                                >
                                                    <a
                                                        href="javascript:void(0)"
                                                        className="btn -register-btn order-0"
                                                        data-toggle="modal"
                                                        data-target="#registerModal"
                                                        tabIndex={-1}
                                                    >
                                                        <img
                                                            src="/build/web/ez-bet-v2/img/btn-register-bg.png"
                                                            className="-btn-bg"
                                                            alt="EZ Slot สมัครสมาชิก"
                                                            width={312}
                                                            height={73}
                                                        />
                                                        <span className="-btn-title">สมัครสมาชิก</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div
                                                className="-inner-heading-wrapper slick-slide"
                                                data-slick-index={3}
                                                aria-hidden="true"
                                                style={{
                                                    width: 451,
                                                    position: "relative",
                                                    left: "-1353px",
                                                    top: 0,
                                                    zIndex: 998,
                                                    opacity: 0,
                                                    transition: "opacity 300ms"
                                                }}
                                                tabIndex={-1}
                                            >
                                                <h2 className="-title">
                                                    โปรโมชั่นสล็อตออนไลน์ สุดฮอต{" "}
                                                    <br className="d-none d-md-block" />{" "}
                                                    โดนใจนักเดิมพันทั้งเก่าและใหม่
                                                </h2>
                                                <p className="-sub-title">
                                                    เตรียมรับแรงกระแทกกับโปรโมชั่น slot พิเศษกว่าใครที่เติมเต็ม
                                                    <br className="d-none d-md-block" />
                                                    แก่นักเดิมพันทั้งเก่าและใหม่
                                                </p>
                                                <div
                                                    className="-btn-wrapper d-none d-lg-inline-block animated fadeInUp"
                                                    data-animatable="fadeInUp"
                                                    data-delay={500}
                                                >
                                                    <a
                                                        href="javascript:void(0)"
                                                        className="btn -register-btn order-0"
                                                        data-toggle="modal"
                                                        data-target="#registerModal"
                                                        tabIndex={-1}
                                                    >
                                                        <img
                                                            src="/build/web/ez-bet-v2/img/btn-register-bg.png"
                                                            className="-btn-bg"
                                                            alt="EZ Slot สมัครสมาชิก"
                                                            width={312}
                                                            height={73}
                                                        />
                                                        <span className="-btn-title">สมัครสมาชิก</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div
                                                className="-inner-heading-wrapper slick-slide slick-current slick-active"
                                                data-slick-index={4}
                                                aria-hidden="false"
                                                style={{
                                                    width: 451,
                                                    position: "relative",
                                                    left: "-1804px",
                                                    top: 0,
                                                    zIndex: 999,
                                                    opacity: 1
                                                }}
                                            >
                                                <h2 className="-title">
                                                    รับประกัน แตกง่าย จ่ายหนัก{" "}
                                                    <br className="d-none d-md-block" />{" "}
                                                    กับแจ็กพอตของเกมสล็อตออนไลน์
                                                </h2>
                                                <p className="-sub-title">
                                                    เล่นง่าย แตกง่าย ทุกเกมไม่รู้จบ ต้องเกม สล็อตออนไลน์{" "}
                                                    <br className="d-none d-md-block" />{" "}
                                                    ที่นี่ที่เดียวรับประกันแตกแจกรางวัลใหญ่ทุกเกมแน่นอน
                                                </p>
                                                <div
                                                    className="-btn-wrapper d-none d-lg-inline-block animated fadeInUp"
                                                    data-animatable="fadeInUp"
                                                    data-delay={500}
                                                >
                                                    <a
                                                        href="javascript:void(0)"
                                                        className="btn -register-btn order-0"
                                                        data-toggle="modal"
                                                        data-target="#registerModal"
                                                        tabIndex={0}
                                                    >
                                                        <img
                                                            src="/build/web/ez-bet-v2/img/btn-register-bg.png"
                                                            className="-btn-bg"
                                                            alt="EZ Slot สมัครสมาชิก"
                                                            width={312}
                                                            height={73}
                                                        />
                                                        <span className="-btn-title">สมัครสมาชิก</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                        : ""}
                {
                    (page === "sport") ?
                    <div className="x-cover x-category-cover-container">
                    <img
                        src="/build/web/ez-bet-v2/img/cover-slot-bg.png"
                        className="-bg"
                        alt="ezslot มีมากกว่า แต่ง่ายกว่า เกมเยอะ ภาพสวย ทั้งใหม่"
                    />
                    <img
                        src="/build/web/ez-bet-v2/img/cover-slot-bg-mobile.png"
                        className="-bg -mobile"
                        alt="ezslot มีมากกว่า แต่ง่ายกว่า เกมเยอะ ภาพสวย ทั้งใหม่"
                    />
                    <div className="container-fluid h-100">
                        <div className="row -contents-wrapper">
                            <div className="col-12 col-md-6 -left-content" />
                            <div className="col-12 col-md-6 -right-content">
                                <div
                                    data-slickable='{"fade":true,"arrows":false,"autoplay":true,"infinite":true,"pauseOnHover":false,"slidesToShow":1,"autoplaySpeed":3000,"speed":300}'
                                    data-animatable="fadeInUp"
                                    data-delay={500}
                                    className="slick-initialized slick-slider animated fadeInUp"
                                >
                                    <div className="slick-list draggable">
                                        <div className="slick-track" style={{ opacity: 1, width: 2255 }}>
                                            <div
                                                className="-inner-heading-wrapper slick-slide"
                                                data-slick-index={0}
                                                aria-hidden="true"
                                                style={{
                                                    width: 451,
                                                    position: "relative",
                                                    left: 0,
                                                    top: 0,
                                                    zIndex: 998,
                                                    opacity: 0,
                                                    transition: "opacity 300ms"
                                                }}
                                                tabIndex={-1}
                                            >
                                                <h1 className="-title">
                                                    Slot Online รวมเกมที่โด่งดัง
                                                    <br className="d-none d-md-block" />
                                                    ที่สุดในโลกไว้ที่นี่ รับประกันความมันส์
                                                </h1>
                                                <p className="-sub-title">
                                                    เล่นง่าย จ่ายจริง นักเดิมพัน Slot Online
                                                    ไม่ควรพลาดร่วมสนุกบน <br className="d-none d-md-block" />{" "}
                                                    ezslot เปิดให้บริการ 24 ชม.
                                                </p>
                                                <div
                                                    className="-btn-wrapper d-none d-lg-inline-block animated fadeInUp"
                                                    data-animatable="fadeInUp"
                                                    data-delay={500}
                                                >
                                                    <a
                                                        href="javascript:void(0)"
                                                        className="btn -register-btn order-0"
                                                        data-toggle="modal"
                                                        data-target="#registerModal"
                                                        tabIndex={-1}
                                                    >
                                                        <img
                                                            src="/build/web/ez-bet-v2/img/btn-register-bg.png"
                                                            className="-btn-bg"
                                                            alt="EZ Slot สมัครสมาชิก"
                                                            width={312}
                                                            height={73}
                                                        />
                                                        <span className="-btn-title">สมัครสมาชิก</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div
                                                className="-inner-heading-wrapper slick-slide"
                                                data-slick-index={1}
                                                aria-hidden="true"
                                                style={{
                                                    width: 451,
                                                    position: "relative",
                                                    left: "-451px",
                                                    top: 0,
                                                    zIndex: 998,
                                                    opacity: 0,
                                                    transition: "opacity 300ms"
                                                }}
                                                tabIndex={-1}
                                            >
                                                <h2 className="-title">
                                                    ระบบที่มีประสิทธิภาพสูงสุด รวดเร็ว{" "}
                                                    <br className="d-none d-md-block" /> สะดวกสบาย
                                                    เพียงคลิกเดียว
                                                </h2>
                                                <p className="-sub-title">
                                                    ezslot เว็บเดิมพันออนไลน์อันดับ 1
                                                    ที่มาพร้อมกับระบบที่ยอดเยี่ยมที่สุด
                                                    <br className="d-none d-md-block" />
                                                    ของเว็บพนันออนไลน์ทั่วประเทศ
                                                </p>
                                                <div
                                                    className="-btn-wrapper d-none d-lg-inline-block animated fadeInUp"
                                                    data-animatable="fadeInUp"
                                                    data-delay={500}
                                                >
                                                    <a
                                                        href="javascript:void(0)"
                                                        className="btn -register-btn order-0"
                                                        data-toggle="modal"
                                                        data-target="#registerModal"
                                                        tabIndex={-1}
                                                    >
                                                        <img
                                                            src="/build/web/ez-bet-v2/img/btn-register-bg.png"
                                                            className="-btn-bg"
                                                            alt="EZ Slot สมัครสมาชิก"
                                                            width={312}
                                                            height={73}
                                                        />
                                                        <span className="-btn-title">สมัครสมาชิก</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div
                                                className="-inner-heading-wrapper slick-slide"
                                                data-slick-index={2}
                                                aria-hidden="true"
                                                style={{
                                                    width: 451,
                                                    position: "relative",
                                                    left: "-902px",
                                                    top: 0,
                                                    zIndex: 998,
                                                    opacity: 0,
                                                    transition: "opacity 300ms"
                                                }}
                                                tabIndex={-1}
                                            >
                                                <h2 className="-title">
                                                    รองรับทุกระบบทั้ง มือถือ และคอม{" "}
                                                    <br className="d-none d-md-block" /> Slot Online
                                                    เจ้าแรกในไทย
                                                </h2>
                                                <p className="-sub-title">
                                                    ร่วมสนุกกับ slot online ได้ทุกระบบไม่ว่าที่ไหน เมื่อไหร่{" "}
                                                    <br className="d-none d-md-block" />{" "}
                                                    ก็สนุกได้เต็มที่มีเพียงอินเตอร์เน็ตอย่างเดียวก็เล่นได้
                                                </p>
                                                <div
                                                    className="-btn-wrapper d-none d-lg-inline-block animated fadeInUp"
                                                    data-animatable="fadeInUp"
                                                    data-delay={500}
                                                >
                                                    <a
                                                        href="javascript:void(0)"
                                                        className="btn -register-btn order-0"
                                                        data-toggle="modal"
                                                        data-target="#registerModal"
                                                        tabIndex={-1}
                                                    >
                                                        <img
                                                            src="/build/web/ez-bet-v2/img/btn-register-bg.png"
                                                            className="-btn-bg"
                                                            alt="EZ Slot สมัครสมาชิก"
                                                            width={312}
                                                            height={73}
                                                        />
                                                        <span className="-btn-title">สมัครสมาชิก</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div
                                                className="-inner-heading-wrapper slick-slide"
                                                data-slick-index={3}
                                                aria-hidden="true"
                                                style={{
                                                    width: 451,
                                                    position: "relative",
                                                    left: "-1353px",
                                                    top: 0,
                                                    zIndex: 998,
                                                    opacity: 0,
                                                    transition: "opacity 300ms"
                                                }}
                                                tabIndex={-1}
                                            >
                                                <h2 className="-title">
                                                    โปรโมชั่นสล็อตออนไลน์ สุดฮอต{" "}
                                                    <br className="d-none d-md-block" />{" "}
                                                    โดนใจนักเดิมพันทั้งเก่าและใหม่
                                                </h2>
                                                <p className="-sub-title">
                                                    เตรียมรับแรงกระแทกกับโปรโมชั่น slot พิเศษกว่าใครที่เติมเต็ม
                                                    <br className="d-none d-md-block" />
                                                    แก่นักเดิมพันทั้งเก่าและใหม่
                                                </p>
                                                <div
                                                    className="-btn-wrapper d-none d-lg-inline-block animated fadeInUp"
                                                    data-animatable="fadeInUp"
                                                    data-delay={500}
                                                >
                                                    <a
                                                        href="javascript:void(0)"
                                                        className="btn -register-btn order-0"
                                                        data-toggle="modal"
                                                        data-target="#registerModal"
                                                        tabIndex={-1}
                                                    >
                                                        <img
                                                            src="/build/web/ez-bet-v2/img/btn-register-bg.png"
                                                            className="-btn-bg"
                                                            alt="EZ Slot สมัครสมาชิก"
                                                            width={312}
                                                            height={73}
                                                        />
                                                        <span className="-btn-title">สมัครสมาชิก</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div
                                                className="-inner-heading-wrapper slick-slide slick-current slick-active"
                                                data-slick-index={4}
                                                aria-hidden="false"
                                                style={{
                                                    width: 451,
                                                    position: "relative",
                                                    left: "-1804px",
                                                    top: 0,
                                                    zIndex: 999,
                                                    opacity: 1
                                                }}
                                            >
                                                <h2 className="-title">
                                                    รับประกัน แตกง่าย จ่ายหนัก{" "}
                                                    <br className="d-none d-md-block" />{" "}
                                                    กับแจ็กพอตของเกมสล็อตออนไลน์
                                                </h2>
                                                <p className="-sub-title">
                                                    เล่นง่าย แตกง่าย ทุกเกมไม่รู้จบ ต้องเกม สล็อตออนไลน์{" "}
                                                    <br className="d-none d-md-block" />{" "}
                                                    ที่นี่ที่เดียวรับประกันแตกแจกรางวัลใหญ่ทุกเกมแน่นอน
                                                </p>
                                                <div
                                                    className="-btn-wrapper d-none d-lg-inline-block animated fadeInUp"
                                                    data-animatable="fadeInUp"
                                                    data-delay={500}
                                                >
                                                    <a
                                                        href="javascript:void(0)"
                                                        className="btn -register-btn order-0"
                                                        data-toggle="modal"
                                                        data-target="#registerModal"
                                                        tabIndex={0}
                                                    >
                                                        <img
                                                            src="/build/web/ez-bet-v2/img/btn-register-bg.png"
                                                            className="-btn-bg"
                                                            alt="EZ Slot สมัครสมาชิก"
                                                            width={312}
                                                            height={73}
                                                        />
                                                        <span className="-btn-title">สมัครสมาชิก</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                        : ""}

                {
                    (page === "lotto") ?
                    <div className="x-cover x-category-cover-container">
                    <img
                        src="/build/web/ez-bet-v2/img/cover-slot-bg.png"
                        className="-bg"
                        alt="ezslot มีมากกว่า แต่ง่ายกว่า เกมเยอะ ภาพสวย ทั้งใหม่"
                    />
                    <img
                        src="/build/web/ez-bet-v2/img/cover-slot-bg-mobile.png"
                        className="-bg -mobile"
                        alt="ezslot มีมากกว่า แต่ง่ายกว่า เกมเยอะ ภาพสวย ทั้งใหม่"
                    />
                    <div className="container-fluid h-100">
                        <div className="row -contents-wrapper">
                            <div className="col-12 col-md-6 -left-content" />
                            <div className="col-12 col-md-6 -right-content">
                                <div
                                    data-slickable='{"fade":true,"arrows":false,"autoplay":true,"infinite":true,"pauseOnHover":false,"slidesToShow":1,"autoplaySpeed":3000,"speed":300}'
                                    data-animatable="fadeInUp"
                                    data-delay={500}
                                    className="slick-initialized slick-slider animated fadeInUp"
                                >
                                    <div className="slick-list draggable">
                                        <div className="slick-track" style={{ opacity: 1, width: 2255 }}>
                                            <div
                                                className="-inner-heading-wrapper slick-slide"
                                                data-slick-index={0}
                                                aria-hidden="true"
                                                style={{
                                                    width: 451,
                                                    position: "relative",
                                                    left: 0,
                                                    top: 0,
                                                    zIndex: 998,
                                                    opacity: 0,
                                                    transition: "opacity 300ms"
                                                }}
                                                tabIndex={-1}
                                            >
                                                <h1 className="-title">
                                                    Slot Online รวมเกมที่โด่งดัง
                                                    <br className="d-none d-md-block" />
                                                    ที่สุดในโลกไว้ที่นี่ รับประกันความมันส์
                                                </h1>
                                                <p className="-sub-title">
                                                    เล่นง่าย จ่ายจริง นักเดิมพัน Slot Online
                                                    ไม่ควรพลาดร่วมสนุกบน <br className="d-none d-md-block" />{" "}
                                                    ezslot เปิดให้บริการ 24 ชม.
                                                </p>
                                                <div
                                                    className="-btn-wrapper d-none d-lg-inline-block animated fadeInUp"
                                                    data-animatable="fadeInUp"
                                                    data-delay={500}
                                                >
                                                    <a
                                                        href="javascript:void(0)"
                                                        className="btn -register-btn order-0"
                                                        data-toggle="modal"
                                                        data-target="#registerModal"
                                                        tabIndex={-1}
                                                    >
                                                        <img
                                                            src="/build/web/ez-bet-v2/img/btn-register-bg.png"
                                                            className="-btn-bg"
                                                            alt="EZ Slot สมัครสมาชิก"
                                                            width={312}
                                                            height={73}
                                                        />
                                                        <span className="-btn-title">สมัครสมาชิก</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div
                                                className="-inner-heading-wrapper slick-slide"
                                                data-slick-index={1}
                                                aria-hidden="true"
                                                style={{
                                                    width: 451,
                                                    position: "relative",
                                                    left: "-451px",
                                                    top: 0,
                                                    zIndex: 998,
                                                    opacity: 0,
                                                    transition: "opacity 300ms"
                                                }}
                                                tabIndex={-1}
                                            >
                                                <h2 className="-title">
                                                    ระบบที่มีประสิทธิภาพสูงสุด รวดเร็ว{" "}
                                                    <br className="d-none d-md-block" /> สะดวกสบาย
                                                    เพียงคลิกเดียว
                                                </h2>
                                                <p className="-sub-title">
                                                    ezslot เว็บเดิมพันออนไลน์อันดับ 1
                                                    ที่มาพร้อมกับระบบที่ยอดเยี่ยมที่สุด
                                                    <br className="d-none d-md-block" />
                                                    ของเว็บพนันออนไลน์ทั่วประเทศ
                                                </p>
                                                <div
                                                    className="-btn-wrapper d-none d-lg-inline-block animated fadeInUp"
                                                    data-animatable="fadeInUp"
                                                    data-delay={500}
                                                >
                                                    <a
                                                        href="javascript:void(0)"
                                                        className="btn -register-btn order-0"
                                                        data-toggle="modal"
                                                        data-target="#registerModal"
                                                        tabIndex={-1}
                                                    >
                                                        <img
                                                            src="/build/web/ez-bet-v2/img/btn-register-bg.png"
                                                            className="-btn-bg"
                                                            alt="EZ Slot สมัครสมาชิก"
                                                            width={312}
                                                            height={73}
                                                        />
                                                        <span className="-btn-title">สมัครสมาชิก</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div
                                                className="-inner-heading-wrapper slick-slide"
                                                data-slick-index={2}
                                                aria-hidden="true"
                                                style={{
                                                    width: 451,
                                                    position: "relative",
                                                    left: "-902px",
                                                    top: 0,
                                                    zIndex: 998,
                                                    opacity: 0,
                                                    transition: "opacity 300ms"
                                                }}
                                                tabIndex={-1}
                                            >
                                                <h2 className="-title">
                                                    รองรับทุกระบบทั้ง มือถือ และคอม{" "}
                                                    <br className="d-none d-md-block" /> Slot Online
                                                    เจ้าแรกในไทย
                                                </h2>
                                                <p className="-sub-title">
                                                    ร่วมสนุกกับ slot online ได้ทุกระบบไม่ว่าที่ไหน เมื่อไหร่{" "}
                                                    <br className="d-none d-md-block" />{" "}
                                                    ก็สนุกได้เต็มที่มีเพียงอินเตอร์เน็ตอย่างเดียวก็เล่นได้
                                                </p>
                                                <div
                                                    className="-btn-wrapper d-none d-lg-inline-block animated fadeInUp"
                                                    data-animatable="fadeInUp"
                                                    data-delay={500}
                                                >
                                                    <a
                                                        href="javascript:void(0)"
                                                        className="btn -register-btn order-0"
                                                        data-toggle="modal"
                                                        data-target="#registerModal"
                                                        tabIndex={-1}
                                                    >
                                                        <img
                                                            src="/build/web/ez-bet-v2/img/btn-register-bg.png"
                                                            className="-btn-bg"
                                                            alt="EZ Slot สมัครสมาชิก"
                                                            width={312}
                                                            height={73}
                                                        />
                                                        <span className="-btn-title">สมัครสมาชิก</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div
                                                className="-inner-heading-wrapper slick-slide"
                                                data-slick-index={3}
                                                aria-hidden="true"
                                                style={{
                                                    width: 451,
                                                    position: "relative",
                                                    left: "-1353px",
                                                    top: 0,
                                                    zIndex: 998,
                                                    opacity: 0,
                                                    transition: "opacity 300ms"
                                                }}
                                                tabIndex={-1}
                                            >
                                                <h2 className="-title">
                                                    โปรโมชั่นสล็อตออนไลน์ สุดฮอต{" "}
                                                    <br className="d-none d-md-block" />{" "}
                                                    โดนใจนักเดิมพันทั้งเก่าและใหม่
                                                </h2>
                                                <p className="-sub-title">
                                                    เตรียมรับแรงกระแทกกับโปรโมชั่น slot พิเศษกว่าใครที่เติมเต็ม
                                                    <br className="d-none d-md-block" />
                                                    แก่นักเดิมพันทั้งเก่าและใหม่
                                                </p>
                                                <div
                                                    className="-btn-wrapper d-none d-lg-inline-block animated fadeInUp"
                                                    data-animatable="fadeInUp"
                                                    data-delay={500}
                                                >
                                                    <a
                                                        href="javascript:void(0)"
                                                        className="btn -register-btn order-0"
                                                        data-toggle="modal"
                                                        data-target="#registerModal"
                                                        tabIndex={-1}
                                                    >
                                                        <img
                                                            src="/build/web/ez-bet-v2/img/btn-register-bg.png"
                                                            className="-btn-bg"
                                                            alt="EZ Slot สมัครสมาชิก"
                                                            width={312}
                                                            height={73}
                                                        />
                                                        <span className="-btn-title">สมัครสมาชิก</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div
                                                className="-inner-heading-wrapper slick-slide slick-current slick-active"
                                                data-slick-index={4}
                                                aria-hidden="false"
                                                style={{
                                                    width: 451,
                                                    position: "relative",
                                                    left: "-1804px",
                                                    top: 0,
                                                    zIndex: 999,
                                                    opacity: 1
                                                }}
                                            >
                                                <h2 className="-title">
                                                    รับประกัน แตกง่าย จ่ายหนัก{" "}
                                                    <br className="d-none d-md-block" />{" "}
                                                    กับแจ็กพอตของเกมสล็อตออนไลน์
                                                </h2>
                                                <p className="-sub-title">
                                                    เล่นง่าย แตกง่าย ทุกเกมไม่รู้จบ ต้องเกม สล็อตออนไลน์{" "}
                                                    <br className="d-none d-md-block" />{" "}
                                                    ที่นี่ที่เดียวรับประกันแตกแจกรางวัลใหญ่ทุกเกมแน่นอน
                                                </p>
                                                <div
                                                    className="-btn-wrapper d-none d-lg-inline-block animated fadeInUp"
                                                    data-animatable="fadeInUp"
                                                    data-delay={500}
                                                >
                                                    <a
                                                        href="javascript:void(0)"
                                                        className="btn -register-btn order-0"
                                                        data-toggle="modal"
                                                        data-target="#registerModal"
                                                        tabIndex={0}
                                                    >
                                                        <img
                                                            src="/build/web/ez-bet-v2/img/btn-register-bg.png"
                                                            className="-btn-bg"
                                                            alt="EZ Slot สมัครสมาชิก"
                                                            width={312}
                                                            height={73}
                                                        />
                                                        <span className="-btn-title">สมัครสมาชิก</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                        : ""}



            </div>
        );
    }
}

export default App;
